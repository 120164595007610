<template>
  <div id="verificationsBlock">
    <!-- Assign Partner Sideabr -->
    <verifications-assign-partner
      :verification-id.sync="tempVerificationId"
      :partner-id.sync="tempPartnerId"
      :is-assign-partner-sidebar-active.sync="isAssignPartnerSidebarActive"
      @refetch-data="refetchData"
    />
    <!-- Filters -->
    <b-card title="Filter Verifications">
      <b-row>
        <b-col md="5" class="form-group" v-if="currentRole != 'Partner'">
          <label for="partner">Partner</label>
          <v-select
            v-model="partner"
            :options="partners"
            placeholder="All Partners"
            :clearable="true"
          />
        </b-col>
        <b-col md="3">
          <label for="userType">User Type</label>
          <v-select v-model="userType" id="userType" placeholder="All Types" :options="userTypes" />
        </b-col>

        <b-col md="4">
          <label for="status">Status</label>
          <v-select v-model="status" :options="statusOptions" placeholder="All Status" />
        </b-col>
      </b-row>
    </b-card>

    <!-- Data -->
    <b-card no-body>
      <div class="p-1">
        <h5 class="mb-0">Verifciations</h5>
      </div>
      <div class="table-responsive">
        <b-table
          :fields="tableFields"
          show-empty
          :busy="isLoading"
          :empty-text="isLoading ? 'Loading...' : 'No Data Available'"
          :items="fetchVerifications"
          ref="refVerificationListTable"
        >
          <template #cell(username)="data">
            <b-link target="_blank" :to="`profile/${data.item.username}`">{{
              data.item.username
            }}</b-link>
          </template>
          <template #cell(currentStatus)="data">
            <b-badge
              :variant="`light-${
                statusOptions.find((x) => x.value == data.item.currentStatus).color
              }`"
              >{{ data.item.currentStatus }}</b-badge
            >
          </template>
          <template #cell(partner)="data"
            ><strong>{{ data.item.partner ? data.item.partner : "Not Assigned" }} </strong>
            <b-button
              variant="success"
              @click="showPartnerAssignModal(data.item._id, null)"
              size="sm"
              v-if="!data.item.partner && currentRole != 'Partner'"
              >Assign</b-button
            >
            <b-button
              @click="showPartnerAssignModal(data.item._id, data.item.partnerId)"
              variant="warning"
              size="sm"
              v-if="data.item.partner && currentRole != 'Partner'"
              >Change</b-button
            >
          </template>
          <template #cell(actions)="data">
            <b-link class="btn btn-sm btn-primary" :to="`/verifications/details/${data.item._id}`"
              >View Details</b-link
            >
          </template>
        </b-table>
        <div class="mx-2">
          <b-row class="border-top mt-1">
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start pt-1"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end py-1"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalVerifications"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormSelect,
  BTable,
  BFormInput,
  BLink,
  BButton,
  BPagination,
  BBadge,
} from "bootstrap-vue";
import store from "@/store";
import vSelect from "vue-select";
import useVerifications from "./useVerifications";
import VerificationsAssignPartner from "./VerificationsAssignPartner";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    BTable,
    BFormInput,
    BLink,
    BButton,
    vSelect,
    VerificationsAssignPartner,
    BPagination,
    BBadge,
  },

  setup() {
    const {
      partners,
      partner,
      status,
      userType,
      fetchVerifications,
      statusOptions,
      refVerificationListTable,
      tempVerificationId,
      tempPartnerId,
      isAssignPartnerSidebarActive,
      showPartnerAssignModal,
      refetchData,
      dataMeta,
      perPage,
      perPageOptions,
      currentPage,
      totalVerifications,
      isLoading,
    } = useVerifications();

    const userTypes = [
      { label: "Company", value: "company" },
      { label: "Freelancer", value: "freelancer" },
      { label: "Investor", value: "investor" },
      { label: "Partner", value: "partner" },
      { label: "Advisor", value: "advisor" },
      { label: "Broker", value: "broker" },
    ];

    const tableFields = [
      { key: "username" },
      { key: "email" },
      { key: "userType" },
      { key: "partner" },
      { key: "currentStatus" },
      { key: "Actions" },
    ];

    return {
      partner,
      partners,
      status,
      statusOptions,
      userTypes,
      userType,
      tableFields,
      fetchVerifications,
      refVerificationListTable,

      tempPartnerId,
      tempVerificationId,
      isAssignPartnerSidebarActive,
      showPartnerAssignModal,
      refetchData,
      dataMeta,
      perPage,
      perPageOptions,
      currentPage,
      totalVerifications,
      isLoading,
    };
  },

  data() {
    return {
      currentUser: null,
      currentRole: null,
    };
  },

  mounted() {
    this.currentUser = store.getters["auth/user"];
    this.currentRole = store.getters["auth/getRole"];
  },
};
</script>
