import axios from "axios";
import { ref, watch, computed } from "@vue/composition-api";

export default function () {
  const partners = ref([]);
  const partner = ref("");
  const status = ref("");
  const userType = ref("");
  const refVerificationListTable = ref(null);
  const perPage = ref(10);
  const totalVerifications = ref(0);
  const perPageOptions = [10, 25, 50, 100];
  const currentPage = ref(1);
  const isLoading = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refVerificationListTable.value
      ? refVerificationListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalVerifications.value,
    };
  });

  const isAssignPartnerSidebarActive = ref(false);
  const tempVerificationId = ref("");
  const tempPartnerId = ref("");

  /* 
  load Partners
  */
  const loadPartners = async () => {
    try {
      const { data } = await axios.get("/verification/partners");
      partners.value = data.data.map((x) => {
        return {
          label: `${x.username} - ${x.partner.fullName}`,
          value: x._id,
        };
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  loadPartners();

  /* 
    Fetch Verifications
  */
  const fetchVerifications = async () => {
    try {
      isLoading.value = true;
      const formData = new FormData();

      if (partner.value) {
        formData.append("partner", partner.value.value);
      }
      if (userType.value) {
        formData.append("userType", userType.value.value);
      }
      if (status.value) {
        formData.append("status", status.value.value);
      }

      formData.append("page", currentPage.value);

      const { data } = await axios.post("/verification/search", formData);
      totalVerifications.value = data.count;
      const finalizedData = data.data.map((e) => {
        return {
          _id: e._id,
          username: e.userId?.username,
          email: e.userId?.email,
          userType: e.userType,
          currentStatus: e.currentStatus,
          partner: e.partnerId?.username || false,
          partnerId: e.partnerId?._id || null,
        };
      });
      return finalizedData;
    } catch (e) {
      console.log("Error Loading Data", e.message);
    } finally {
      isLoading.value = false;
    }
  };

  const statusOptions = [
    { label: "Draft", value: "Draft", color: "dark" },
    { label: "On Hold", value: "On Hold", color: "info" },
    { label: "Assigned to Partner", value: "Assigned to Partner", color: "warning" },
    { label: "Verified - Partner", value: "Verified - Partner", color: "success" },
    { label: "Verified", value: "Verified", color: "success" },
    { label: "Rejected - Partner", value: "Rejected - Partner", color: "danger" },
    { label: "Rejected", value: "Rejected", color: "danger" },
    { label: "Refunded", value: "Refunded", color: "secondary" },
    { label: "Archive", value: "Archive", color: "secondary" },
  ];

  const refetchData = () => {
    refVerificationListTable.value.refresh();
  };

  watch([partner, status, userType, currentPage], async () => {
    refetchData();
  });

  const showPartnerAssignModal = (verificationId, parterId) => {
    tempVerificationId.value = verificationId;
    tempPartnerId.value = parterId;
    isAssignPartnerSidebarActive.value = true;
  };

  return {
    partner,
    status,
    userType,
    partners,
    fetchVerifications,
    statusOptions,
    refVerificationListTable,
    tempPartnerId,
    tempVerificationId,
    isAssignPartnerSidebarActive,
    showPartnerAssignModal,
    refetchData,
    dataMeta,
    perPage,
    perPageOptions,
    currentPage,
    totalVerifications,
    isLoading,
  };
}
